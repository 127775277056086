.wp_container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
}

.wp_content {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.wp_headerView {
  display: flex;
  height: 60px;
  background: #FAF7EA;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 10px 0;
}

.wp_headerView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 968px) {
  .wp_headerView{
    padding: 15px 20px;
  }
}

.wp_navigationLinks {
  display: flex;
  gap: 20px;
  transition: all 0.3s ease-in-out;
}


@media (max-width: 768px) {
  .wp_navigationLinks {
    display: none; 
    position: fixed; 
    top: 0;
    right: 0;
    height: 100%;
    width: 230px;
    background-color: #fff;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
    padding: 52px;
  transition: transform 0.3s ease-in-out;
   
  }

  .wp_navigationLinks.open {
    display: flex; 
  }
}


.wp_burgerMenu {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}


@media (max-width: 768px) {
  .wp_burgerMenu {
    display: block;
  }
}


.wp_linkNButtonView {
  display: flex;
  gap: 15px;
}


@media (max-width: 768px) {
  .wp_linkNButtonView {
    display: none;
    
  }
}
.wp_logoView {
  display: flex;
  width: 116px;
  height: 42px;
  margin-left: 50px;
}
@media (max-width: 968px) {
  .wp_logoView {
     margin-left: 0%;
    
  }
}

.wp_logoStyle {
  display: flex;
  width: 100%;
  height: 100%;
}

.wp_navView {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
@media (max-width: 768px) {
  .wp_navView {
    flex-direction: column;
  }
}

.wp_navLinkView {
       
}

.wp_navLinkStle {
  color: #576268;
  font-family: RubikRegular;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.wp_popupItemView {
  display: flex;
  margin: 10px 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.wp_popupItemStyle {
  display: flex;
  width: 100%;
  color: #576268;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}

.wp_linkNbuttonView {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  margin-right: 50px;
}
@media (max-width: 968px) {
  .wp_linkNbuttonView {
    margin-right: 0%;
  }
}

.wp_linkView {
  display: flex;
  width: 100%;
  padding: 10px 0px;
  flex-wrap: wrap;
}

.wp_linkStyle {
  display: flex;
  text-align: right;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
  background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  flex-wrap: nowrap;
}

.wp_buttonView {
  display: flex;
  padding: 10px 0px;
  border-radius: 30px;
  background: linear-gradient(270deg, #0E4969 0.01%, #0173B0 105.19%);
}

.wp_buttonTextStyle {
  color: #FFF;
  text-align: center;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
}

.wp_bannersView {
  display: flex;
  scroll-margin: 100px;
  flex-direction: column;
  width: 100%;
  height: auto;
}



.wp_serviceTitleStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 56px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
@media (max-width: 968px) {
  .wp_serviceTitleStyle {
    font-size: 45px;
  }
}

@media (max-width: 468px) {
  .wp_serviceTitleStyle {
    font-size: 35px;
  }
}

.wp_serviceContentView {
  
  display: flex;
  width: 80%;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}
@media (max-width: 768px) {
  .wp_serviceContentView {
   flex-direction: column;
  }
  .wp_serviceContentView{
    align-items: center;
  }
}
@media (max-width: 520px) {
  .wp_serviceContentView{
    width: 94%;
  }
 
}

.wp_financialContentView {
  display: flex;
  padding: 32px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background: rgba(189, 217, 205, 0.30);
}

.wp_serviceSubHeadingStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.wp_serviceSubLabelStyle {
  margin-top: -24px;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.wp_serviceLabelStyle {
  color: #576268;
  text-align: center;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  height: 200px;
  line-height: 1.5;
}
.wp_wrapper{
  
}

.wp_wealthContentView {
  display: flex;
  padding: 32px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background: rgba(237, 231, 180, 0.30);
}
.wp_itemsOuterView {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  justify-content: center; 
  margin: 0 auto; 
}

.wp_itemsOuterView > div {
  flex: 1 1 calc(33.33% - 40px); 
  max-width: calc(33.33% - 40px); 
  box-sizing: border-box; 
  padding: 10px;
  margin-bottom: 20px; 
}

@media (max-width: 768px) {
  .wp_itemsOuterView > div {
    flex: 1 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
  }
}


@media (max-width: 480px) {
  .wp_itemsOuterView > div {
    flex: 1 1 100%;
    max-width: 100%;
  }
}


.wp_itemView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.wp_itemStyle {
  display: flex;
  width: 40px;
  height: 40px;
}

.wp_itemTextStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  height: 20px;
}
.grid-header {
  margin-bottom: 10px;
  color: #013F60; 
  font-family: RubikRegular; 
  font-size: 18px; 
 
}
.grid-header-sub {
  margin-bottom: 10px; 
  color: #013F60; 
  font-family: RubikRegular; 
  font-size: 12px; 
 
}
.investorCharterContainer {
   font-family: RubikRegular;
  color: #333;
  padding: 20px;
  background-color: #f4f6f8;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  margin: auto;
}


.investorCharterContainer {
  font-family: RubikRegular;
  color: #333;
  padding: 20px;
}

.investorCharterTitle {
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 700;
  color: #013F60;
  margin-bottom: 20px;
}

.investorCharterContent {
  font-family: RubikRegular;
  font-size: 16px;
  margin-top: 30px;
}

.investorCharterSection {
  margin-bottom: 20px;
}
.grievanceRedressalSection {
  font-family: RubikRegular;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  margin: 40px auto;
  max-width: 900px;
}
@media (max-width: 480px) {
  .grievanceRedressalSection {
    padding: 20px;
  }
}

.grievanceTitle {
  font-size: 32px;
  font-weight: 700;
  color: #013F60;
  margin-bottom: 30px;
  text-align: center;
  border-bottom: 3px solid #013F60;
  padding-bottom: 10px;
}

.grievanceContent {
  line-height: 1.6;
  color: #333;
  display: flex;
  flex-direction: column;
  padding: 6rem 0 6rem 0;

}
@media (max-width: 480px) {
 .grievanceContent {
    padding: 2rem;
  }
}
.grievanceContent p {
  margin-bottom: 20px;
  font-size: 18px;
  font-family: RubikRegular;
}

.emailLink, .externalLink {
  color: #013F60;
  text-decoration: none;
  font-weight: bold;
}

.emailLink:hover, .externalLink:hover {
  text-decoration: underline;
}

.phoneLink {
  color: #013F60;
  text-decoration: none;
  font-weight: bold;
}

.phoneLink:hover {
  text-decoration: underline;
}
.investorCharterSubtitle {
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 600;
  color: #013F60;
  margin-bottom: 10px;
}

.investorCharterList,
.investorCharterOrderedList {
  margin-left: 20px;
  line-height: 1.6;
  font-family: RubikRegular;
  font-size: 18px;

}
.wp_servicesView {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.grid-header {
  font-size: 24px;
  margin: 10px 0;
  color: #013F60;
}

.grid-header-sub {
  font-size: 14px;
  margin-top: 5px;
  color: #666;
}

.grid-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; 
}


@media (max-width: 768px) {
  .grid-header {
    font-size: 20px;
  }

  .grid-header-sub {
    font-size: 12px;
    word-wrap: break-word; 
  }

  .ag-theme-alpine {
    font-size: 14px; 
    overflow-x: auto; 
  }

  .wp_servicesView {
    padding: 4rem 2rem !important; 
  }
}
.wp_ourservicesView{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:4rem 1rem;
  gap: 1rem;
}
.wp_servicesView {
  padding: 6rem 4rem 6rem 4rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.rmh_tableView {
  display: flex;
  flex-direction: column;

  overflow-x: auto; 
  width: 100%;
  height: 100%;
}

.grid-container {
  width: 100%;
  max-width: 950px;
  height: 400px;
  flex-shrink: 0; 
}



.ag-container {
  display: flex;
  flex-direction: column;
  gap: 20px; 
  width: 80%; 
}

.ag-theme-alpine {
  width: 100%; 
  align-self: center;
}

.investorCharterLink {
  color: #013F60;
  text-decoration: underline;
}

.investorCharterLink:hover {
  color: #025C77;
}
.visionBlock, .missionBlock {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.visionBlock {
  background: linear-gradient(135deg, #f0f8ff, #d0e8f2);
  margin-bottom: 20px;
}

.missionBlock {
  background: linear-gradient(135deg, #f9fbe7, #e3f2fd);
}
.wp_testScoreView {
  display: flex;
  width: 100%;
  padding:3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
   box-sizing: border-box;
   gap: 2rem;
  background: linear-gradient(180deg, #F5FBFF 64.96%, rgba(238, 242, 244, 0.00) 100%);
}
@media (max-width: 968px) {
 .wp_testScoreView{
  padding: 20px;
 }
}

.visionTitle{
  font-family: RubikRegular;
  font-size: 24px;
  color: #025C77;
}
.wp_testTitleStyle {
  display: flex;
  width: 90%;
  color: #013F60;
  text-align: center;
  justify-content: center;
  font-family: RubikRegular;
  font-size: 56px;
  font-weight: 500;
}

.wp_testContentsView {
   display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  justify-content: center; 
}
.wp_testContentsView > div {
  flex: 1 1 calc(33.33% - 30px); 
  max-width: calc(33.33% - 30px);
  box-sizing: border-box;
  padding: 20px;
 
  border-radius: 8px; 
  margin-bottom: 20px; 
}
@media (max-width: 768px) {
  .wp_testContentsView > div {
    flex: 1 1 calc(50% - 20px); 
    max-width: calc(50% - 20px);
  }
}


@media (max-width: 540px) {
  .wp_testContentsView > div {
    flex: 1 1 100%; 
    max-width: 100%;
  }

  .wp_testTitleStyle {
    font-size: 20px; 
  }
}

.wp_testItemView_1,
.wp_testItemView_2,
.wp_testItemView_3 {
 display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease; 
}

.wp_testItemView_1 {
  background: linear-gradient(127deg, #FFF 0%, #E7FFFC 71.61%), #FFF;
}

.wp_testItemView_2 {
  background: linear-gradient(121deg, #FFF 0.72%, #E3FFE6 96.12%);
}

.wp_testItemView_3 {
  background: linear-gradient(120deg, #FFF -0.6%, #FEFFD8 100%);
}

.wp_testItemView_1:hover,
.wp_testItemView_2:hover,
.wp_testItemView_3:hover {
 box-shadow: 0px 4px 12px rgba(0, 62, 96, 0.234);
}



.wp_testItemStyle {
  display: flex;
  align-self: flex-start;
}

.wp_itemTitleStyle {
  color: #013F60;
  font-family: RubikMedium;
  font-size: 22px;
  font-weight: 500;
}

.wp_itemDescStyle {
  min-height: 70px;
  color: #576268;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
 
}

.wp_itemLinkStyle {
  text-align: left;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  background: var(--CTA, linear-gradient(270deg, #003049 0.01%, #005887 105.19%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wp_aboutUsView {
  padding: 6rem;
  max-width:100%;
align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}


.wp_aboutContentView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.wp_aboutDescView {
  flex: 1;
}

.wp_aboutDescStyle {
  font-size: 16px;
  
  margin-bottom: 10px;
  color: #333;
}

.abt_img {
  width: 600px; 
  height: auto;
  border-radius: 8px;
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .wp_aboutContentView {
    flex-direction: column; 
    align-items: center; 
  }

  .abt_img {
    width: 500px; 
    margin-top: 20px; 
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .wp_aboutUsView {
    padding:4rem 2rem;
  }
 .abt_img {
    width: 100%; /* Full width on mobile */
    border-radius: 5px;
  }
}
.wp_aboutDescStyle {
  display: block;
  font-size: 16px;

  font-family: RubikRegular;
  margin-bottom: 15px;
  text-align: justify;
}

.wp_aboutImage {
  width: 100%; 
  height: auto; 
  max-width: 400px; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

/* Tablet View */
@media (max-width: 800px) {
  .wp_aboutContentView {
    flex-direction: column; 
  }

  .wp_aboutDescView,
  .wp_aboutImage {
    max-width: 100%;
  }

  
}

/* Mobile View */
@media (max-width: 480px) {
  .wp_aboutTitleStyle {
    font-size: 24px; 
  }

  
}
.wp_teamView {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(255, 254, 250, 0.00) 0%, #FCF3DF 111.86%);
}

.wp_teamTitleStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 56px;
  font-weight: 500;
}

.wp_teamContentView {
  display: flex;
  margin: 40px 0;
  flex-direction: row;
  width: 80%;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 880px) {
  .wp_teamContentView {
   flex-direction: column;
  }

  
}

.wp_leaderView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;


  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
}

.wp_leaderImgView {
  display: flex;
  width: 250px;
  height: 300px;
}

.wp_leaderImgStyle {
  display: flex;
  width: 100%;
  height: 100%;
}

.wp_nameView {
  display: flex;
  width: calc(100% - 80px);
  margin: 24px 40px 16px 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.wp_nameStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 500;
}

.wp_linkedinStyle {
  display: flex;
  width: 24px;
  height: 24px
}

.wp_nameDescStyle {
  display: flex;
  width: calc(100% - 80px);
  min-height: 220px;
  margin: 0 40px 40px 40px;
  color: #474747;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
}

.wp_testimonialsView {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  margin: 60px 40px;
  align-items: center;
}

.wp_testimonialTitleStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 56px;
  font-weight: 500;
}

.wp_testimonialContentView {
  margin: 24px 0;
  display: flex;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.wp_contactView {
  display: flex;
  flex-direction: column;
  background: #003049;
  padding: 40px 40px 0 40px;
}

.wp_contactTopView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.wp_logoNcompanyView {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.wp_logoLightStyle {
  display: flex;
  width: 130px;
  height: 55px;
}

.wp_companyStyle {
  color: #FFF;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
}

.wp_separatorStyle {
  margin: 16px 0;
  display: flex;
  width: 100%;
  border: 1px solid #FFF;
  opacity: 0.2;
  background: #FFF;
}
.color_text{
  color: #FFF;
}

.wp_contactBottomView {

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.wp_sebicontactValueStyle{
 margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  color: #FFF;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
  margin: 10px;
}
.wp_contactDetailsView {
  display: flex;
  flex-direction: column;

  justify-content: center;
  width: 100%;

  
}
.wp_sebicontactDetailsView{
   display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
}

.wp_contactTitleStyle {
  display: flex;
  color: #FFF;
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.wp_sebicontactTitleStyle {
  display: flex;
  color: #ff6767;
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.wp_contactItemView {
  display: flex;
  justify-content: center;
  padding: 20px;
  
}

.wp_contactRowStyle {
  display: flex;
 gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  color: #FFF;
  flex-direction: row;
}
.linktext{
  color: #409bf6;
}
.address_text{

  font-family: RubikRegular;
  font-size: 15px;
}
.wp_contactIconStyle {
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.wp_contactLableStyle {
  display: flex;
 
  color: #FFF;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.wp_contactValueStyle {
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.wp_verticalSeparatorStyle {
  display: flex;
  height: 100%;
  border: 1px solid #FFF;
  opacity: 0.2;
  background: #FFF;
  transform: rotate(180deg);
}

.wp_followView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.wp_followTitleStyle {
  display: flex;
  color: #FFF;
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.wp_followContentView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
  gap: 16px;
}

.wp_followItemView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.wp_followIconStyle {
  display: flex;
  width: 24px;
  height: 24px;
}

.wp_followLabelStyle {
  display: flex;
  color: #FFF;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.wp_extraView {
  display: flex;
  margin-right: 24px;
}

.wp_footerView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #002234;
  padding: 12px 0;
}

.wp_footerTextStyle {
  color: #FFF;
  text-align: center;
  font-family: RubikRegular;
  font-size: 13px;
  font-weight: 400;
}
.keypersons-table {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.keypersons-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}


.keypersons-table th,
.keypersons-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  color: white;
}


@media (max-width: 768px) {
  .keypersons-table {
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
    width: 95%;
  }

  .keypersons-table table {
    border: 0;
  }
  
  .keypersons-table thead {
    display: none;
  }

  .keypersons-table tr {
    display: block;
    margin-bottom: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }

  .keypersons-table td {
    display: block;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    padding: 8px;
    color: #fff;
  }

  .keypersons-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
  }
}

.table_head{
  background-color: rgba(128, 128, 128, 0.201);
}
.warning_text{
  color: #ff6767;
  font-family: RubikMedium;
  font-size: 16px;
   font-weight: 500;
}
.warning_value{
  color: white;
  font-family: RubikRegular;
  font-size: 15px;
   font-weight: 400;
}
.keypersons-table td {
 padding: 8px 16px;
  text-align: left;
 border: 2px solid #dddddd30;
  font-family: RubikRegular;
  color: white;
}



.keypersons-table a {
  color: #409bf6;
  text-decoration: none;
}

.keypersons-table a:hover {
  text-decoration: underline;
}
.contact-footer-container {
  color: #fff;
  text-align: center;
  width: 100%;
}

.company-details {
  max-width: 860px;
  margin: 0 auto;
  padding: 20px;
}

.company-logo {
  width: 120px;
  margin-bottom: 10px;
}

.company-text {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-family: RubikRegular;
  line-height: 1.6;
}

.company-registration {
  font-size: 13px;
  color: #ffd700;
  margin-top: 10px;
  font-family: RubikRegular;
}

@media (max-width: 768px) {
  .company-details {
    padding: 15px;
  }

  .company-logo {
    width: 100px;
  }

  .company-text, .company-registration {
    font-size: 12px;
  }
}

.footer-section {
  color: #fff;
  text-align: center;
}

.item-column {
  display: flex;
  flex-direction: column;
}

.footer-item {
  margin-bottom: 20px;
  text-align: center;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.footer-label {
  font-size: 16px;
  font-weight: 600;
  color: #f0f0f0;
}

.footer-value {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.link-text {
  color: #ffd700;
  text-decoration: none;
}

.link-text:hover {
  text-decoration: underline;
}

.footer-address {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
}

.footer-warning-text {
  font-size: 14px;
  font-weight: bold;
  color: #ff4d4d;
}

.footer-warning-value {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-family: RubikRegular;
}

@media (max-width: 768px) {
  .footer-item {
    margin-bottom: 12px;
    flex-direction: column;
  }

  .footer-label,
  .footer-value,
  .footer-warning-text,
  .footer-warning-value {
    font-size: 14px;
  }

  .flex-row {
    gap: 0.5rem;
  }
}

.footer-contact-row {
  gap: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer-contact-label {
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
  flex: 1 1 auto;
  text-align: center;
}

.footer-contact-value {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  flex: 1 1 auto;
  text-align: center;
}

.footer-link-text {
  color: #ffd700;
  text-decoration: none;
}

.footer-link-text:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-contact-row {
    flex-direction: column;
    align-items: center;
  }

  .footer-contact-label,
  .footer-contact-value {
    margin-bottom: 5px;
  }
}
